
.card-container{
    max-width: 1110px;
    width: 100%;
    margin: 20px auto;
}
.heading-h3{
    font-size: 24px;
    font-weight: 600;
    color: #000;
    margin: 0;
}
.card-parent{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}
.heading-h5{
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin: 20px 0px;
}
.mb-20{
    margin-bottom: 20px;
}
.card-parent .card-left{
    width: 70%;
}
.card-parent .card-right{
    width: calc(30% - 20px);
}
.card-box-parent .card-row-head{
    border: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card-box-parent.border-top{
    border-top: 1px solid #e6e6e6;
}
.card-box-parent .card-row-head h6{
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 0;
    padding: 10px 20px;
}
.card-box-parent .card-box-body {
    padding: 10px 20px;
}
.card-box-body{
    border: 1px solid #e6e6e6;
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.td-type{
    padding: 4px 0px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.td-type h6{
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin: 0 10px;
}
.product-img{
    width: 80px;
    height: 110px;
    object-fit: cover;
}
.node-box{
    border: 1px solid #e6e6e6;
    padding: 10px;
    margin: 10px 0px;
}
.node-box .h6{
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-bottom: 10px;
}

.node-box .btn-full{
    max-width: 150px;
}
.card-right .card-right-box{
    border: 1px solid #e6e6e6;
    padding: 20px;
    margin-bottom: 20px;
}
.card-right .card-right-box .heading-h5{
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-top: 0px;
}
.apply-code-row{
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

}
.apply-code-row input{
    margin: 0em 0;
    width: 100%;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.8);
    box-sizing: border-box;
    padding: 10px 110px 10px 10px;
    border: 1px solid #001C46;
    border-radius: 10px;
}
.btn-apply-code{
    position: absolute;
    right: 2px;
    top: 2px;
    background: #001C46;
    color: #fff;
    border-radius: 10px;
    padding: 9px 12px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
}
.btn-apply-code.remove{    
    background: rgb(255, 111, 111);
}
.apply-code-row>span.error{
   position: absolute;
   left: 0;
   bottom: -16px;
   font-size: 12px;
   color: red;
}
.bold{
    font-weight: 600;
}
.btn-full{
    background: #001C46;
    color: #fff;
    border-radius: 10px;
    padding: 12px 12px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    border: none;
    margin-top: 20px;
    width: 100%;
    cursor: pointer;
}
.close-icon{
    width: 20px;
    margin: 0 10px 0px 0px; 
    cursor: pointer;
}
.back-arrow{
    width: 20px;
    margin: 0 10px 0px 0px; 
}
.heading-h5-continue{
    display: flex;
    margin-top: 20px;
    cursor: pointer;
}
.align-top{
    align-items: flex-start;
}
.column{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.apply-text{
    font-size: 10px;
    font-weight: 600;
    color: green;
    margin: 4px 0 0 0px;
    padding: 2px 8px 4px 8px;
    background: #b5d1bf;
    border-radius: 4px;
    line-height: 1;
    
}
.clear-icon{
    color: green;
    margin-left: 10px;
    cursor: pointer;
    font-size: 14px;
}
.coupon-code-box{
    padding: 30px 0px;
}
.coupon-code-box .h3{
    margin-bottom: 20px;
}

.w-100{
    width: 100%;
}


.modal-wrapper{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
    display: none;
    align-items: center;
    justify-content: center;
    background: #00000059;
}
.modal-wrapper #shop_window_new{
    height: auto;
    width: 100%;
    background: white;
    border-radius: 8px;
    max-width: 410px;
    padding: 20px 24px 30px 24px;
    margin: auto;
    position: relative;
}
.modal-wrapper #shop_window_new .close-icon{
    width: 20px;
    font-size: 20px;
    margin: 0 0px 0px 0px; 
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
}
.bottom-end{
    display: flex;
    align-items: center;
    justify-content: flex-end;    
    margin-top: 16px;
}
.bottom-end .MuiButtonBase-root{
    margin-left: 10px;
}
.bottom-end .MuiButton-containedPrimary{
    background: #001C46;
}
.modal-wrapper .mb-3{
    margin-bottom: 16px;
}